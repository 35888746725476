<template>
  <div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      <a-button type="primary" icon="sync" @click="handleSyncData" v-if="false">同步分类</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      rowKey="code"
    >
      <span slot="sort_order_tags" slot-scope="sort_order_tags">
        <a-tag
          :color="sort_order_tags === 1 ? 'volcano' : sort_order_tags === 2 ?'green' : 'geekblue'"
        >
          {{ sort_order_tags === 1 ? '一级类目' : sort_order_tags === 2 ?'二级类目' : '三级类目' }}
        </a-tag>
      </span>
      <span slot="img" slot-scope="img, record">
        <a-avatar
          slot="img"
          :src="img"
          shape="square"
          @click="openPreview(img)"
        />
        <a :href="img" target="_blank" v-if="false">{{ record.name }}</a>
      </span>
      <span slot="img2" slot-scope="img2, record">
        <a-avatar
          v-if="(getFileSuffix(img2) !== 'pdf') && img2"
          slot="img2"
          :src="img2"
          shape="square"
          @click="openPreview(img2)"
        />
        <a :href="img2" v-if="getFileSuffix(img2) === 'pdf'" target="_blank">{{ record.name }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="图片错误" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'
import {
  goods_category_create,
  goods_category_delete,
  goods_category_list,
  sync_goods_category
} from '@/api/applet_goods_category'
import CreateForm from '@/views/a-applet/goods_category/modules/CreateForm'
import EditableCell from '@/components/EditableCell/index'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    EditableCell
  },
  data () {
    return {
      loading: false,
      confirmLoading: true,
      // 创建窗口控制
      visible: false,
      previewvisible: false,
      previewurl: '',
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '编号',
          dataIndex: 'code',
          width: 200,
          align: 'left'
        },
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '图片',
          dataIndex: 'picture',
          ellipsis: true,
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '级别',
          dataIndex: 'level',
          ellipsis: true,
          scopedSlots: { customRender: 'sort_order_tags' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return goods_category_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            const entries = res.data.entries
            const level1 = []
            const level2 = []
            const level3 = []
            entries.forEach((e, i) => {
              if (e.level === 1) {
                level1.push(e)
              } else if (e.level === 2) {
                level2.push(e)
              } else {
                level3.push(e)
              }
            })

            level1.forEach((e, i) => {
              e.children = []
              level2.forEach((e2, i2) => {
                e2.children = []
                if (e.code === e2.code.substr(0, 2)) {
                  e.children.push(e2)
                }
                level3.forEach((e3, i3) => {
                  if (e2.code === e3.code.substr(0, 4)) {
                    e2.children.push(e3)
                  }
                })
              })
            })
            res.data.entries = level1
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelete (record) {
      goods_category_delete(record.id).then((res) => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          if (!this.checkValuesIsOk(values)) {
            this.$message.warning('层级和编号长度不对应')
            this.confirmLoading = false
            return
          }
          goods_category_create(values).then((res) => {
            console.log(res)
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
            this.visible = false
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    checkValuesIsOk (values) {
      return values.code.length === values.level * 2
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    getFileSuffix: function (filename) {
      if (filename === undefined || filename === '') {
        return ''
      }
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    openPreview (url) {
      if (url === undefined || url === '') {
        return
      }
      this.previewvisible = true
      this.previewurl = url
    },
    handleSyncData () {
      sync_goods_category().then((res) => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    }
  }
}
</script>
